<template>
  <div class="gestion-categorie-th">
    <div class="box-setting-header">
      <div class="title loaderPosition">
        <div class="widthTitle">Gestion type des sociétés</div>
        <div v-if="loadingForFetch" class="loading">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button
          v-if="checkPermission('GTSFLA')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addTypeSte
        >
          <font-awesome-icon icon="plus" /> Ajouter un type
        </b-button>
        <b-modal
          id="addTypeSte"
          ref="addTypeSte"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un type</div>
            <div class="iconClose" @click.prevent="hideModal('addTypeSte')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitType"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Type societe"
                  label-for="nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="tyype"
                    v-model="type"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul
                        v-if="Array.isArray(error)"
                        style="list-style-type: none"
                      >
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style"
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width: 100%">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-gestion-categories-style"
          :items="getTypeSociete"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(type_societe)="data" class="nomCategorieSize">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(Actions)="data" class="actionCategorieSize">
            <b-button
              v-if="checkPermission('GTSFLM')"
              class="button-danger-style m-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="deleteCurrentType(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('GTSFLSnpm')"
              class="button-danger-style m-2"
              size="sm"
              variant="success"
              @click.prevent.stop="updateCurrentType(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal
      ref="deleteTypeSte"
      id="deleteTypeSte"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un type</div>
        <div class="iconClose" @click.prevent="hideModal('deleteTypeSte')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleDeleteType"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3 text-dark">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer ce type?
              </p>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="'error' + index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style mt-1"
                :disabled="loading"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form> </template
      ></Card>
    </b-modal>
    <b-modal
      ref="updateTypeSte"
      id="updateTypeSte"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier un type</div>
        <div class="iconClose" @click.prevent="hideModal('updateTypeSte')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdatetypeSte"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Type societe"
              label-for="nom"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="typeSte"
                v-model="type"
                required
              ></b-form-input>
            </b-form-group>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" style="list-style-type: none">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style"
                :disabled="loading"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form> </template
      ></Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-des-catégories',
  data() {
    return {
      type: null,
      error: null,
      loadingForFetch: false,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      fields: [
        {
          key: 'type_societe',
          label: 'Type Societe',
          thStyle: { width: '100%' },
        },
        { key: 'Actions', label: 'Actions' },
      ],
      typeToDelete: null,
      typeToUpdate: null,
    };
  },
  computed: {
    ...mapGetters([
      'getTypeSociete',
      'getTypeSocieteLoading',
      'getTypeSocieteError',
      'getTypeSocieteDetailsLoading',
      'getTotalRowTypeSociete',
      'checkPermission',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchAllTypeSociete',
      'addNewTypeSte',
      'deletetypeSte',
      'updateTypeSte',
    ]),
    resetModal() {
      this.type = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },

    async handleSubmitType() {
      this.loading = true;
      const response = await this.addNewTypeSte({
        type: this.type,
      });
      if (response) {
        this.loading = false;
        this.hideModal('addTypeSte');
      } else {
        this.error = this.getTypeSocieteError;
        this.loading = false;
      }
    },
    deleteCurrentType(data) {
      this.typeToDelete = data;
      this.$refs['deleteTypeSte'].show();
    },

    async handleDeleteType() {
      this.loading = true;
      const response = await this.deletetypeSte(this.typeToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteTypeSte');
      } else {
        this.loading = false;
      }
    },
    updateCurrentType(data) {
      this.type = data.type;
      this.typeToUpdate = data;
      this.$refs['updateTypeSte'].show();
    },
    async handleUpdatetypeSte() {
      this.loading = true;
      const response = await this.updateTypeSte({
        typeSte: this.typeToUpdate,
        type: this.type,
      });
      if (response) {
        this.loading = false;
        this.hideModal('updateTypeSte');
      } else {
        this.loading = false;
        this.error = this.getTypeSocieteError;
      }
    },
  },
  components: {
    Card: () => import('../component/card'),
  },
  async mounted() {
    this.loadingForFetch = true;
    await this.fetchAllTypeSociete();
    this.loadingForFetch = false;
  },
};
</script>

<style lang="scss" scoped>
.gestion-categorie-th {
  height: 100%;
  .tabs-categorie {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .categorie-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .categorie-body {
    display: flex;
    margin: 5px 15px;
    height: calc(100vh - 86px);
    .categorie-details {
      width: 75%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 198px);
        height: calc(100% - 198px);
        width: 100%;
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-categories-style {
    max-height: calc(100vh - 247px) !important;
    height: calc(100vh - 247px) !important;
    margin-bottom: 0px;
    max-width: 100%;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 200px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
