<template>
  <div class="contentProduct">
    <div class="entete">
      <div class="box-setting-header">
        <div class="title loaderPosition">
          <div class="widthTitle">Gestion services</div>
          <div v-if="loadingService" class="loading">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
          <div class="error-message ml-3">
            <div v-if="errorService" class="error">
              <ul v-if="Array.isArray(errorService)" class="mb-0">
                <li v-for="(e, index) in errorService" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ errorService }}</div>
            </div>
          </div>
        </div>
        <div>
          <b-button
            v-if="checkPermission('GSHTAS')"
            variant="success"
            class="config-btn ml-2"
            v-b-modal.addService
            ><font-awesome-icon icon="plus" /> Ajouter un service</b-button
          >
          <b-modal
            id="addService"
            ref="addService"
            title="Nouveau service"
            no-close-on-backdrop
            :hide-footer="true"
            :hide-header="true"
            @hidden="resetModal"
            modal-class="cutsom-modal-bootstrap modal-dialog-service"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Nouveau service</div>
              <div class="iconClose" @click.prevent="hideModal('addService')">
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <form
                  @submit.prevent="submitAddService"
                  class="form-modal-custom-style"
                >
                  <b-form-group
                    label="Nom"
                    label-for="nom"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="nom"
                      v-model="serviceToAdd.nom"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <div class="messageError">
                    <div v-if="errorNomService" class="error">
                      <ul
                        v-if="Array.isArray(errorNomService)"
                        class="mb-0"
                        style="list-style-type: none"
                      >
                        <li v-for="(e, index) in errorNomService" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorNomService }}</div>
                    </div>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Description"
                      label-for="description"
                      class="input-modal-champ"
                    >
                      <b-form-textarea
                        id="description"
                        row="5"
                        v-model="serviceToAdd.description"
                        style="border-radius: 17px; height: 150px"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <div class="actionModel">
                    <div class="messageError">
                      <div v-if="errorService" class="error">
                        <ul
                          v-if="Array.isArray(errorService)"
                          class="mb-0"
                          style="list-style-type: none"
                        >
                          <li v-for="(e, index) in errorService" :key="index">
                            {{ e }}
                          </li>
                        </ul>
                        <div v-else>{{ errorService }}</div>
                      </div>
                    </div>
                    <b-button
                      type="submit"
                      class="button-valide-style"
                      :disabled="loading"
                    >
                      <span>
                        Valider
                        <div v-if="loading" class="loading ml-2">
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </span>
                    </b-button>
                  </div>
                </form>
              </template>
            </Card>
          </b-modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width: 100%">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-gestion-categories-style"
          :items="services"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(nom)="data" class="nomCategorieSize">
            {{ data.item.nom }}
          </template>
          <template v-slot:cell(description)="data" class="nomCategorieSize">
            {{ data.item.description ? data.item.description : '-' }}
          </template>
          <template v-slot:cell(actions)="data" class="actionCategorieSize">
            <b-button
              class="button-danger-style m-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="handleDeleteService(data.item)"
              v-if="checkPermission('GSHTSS')"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('GSHTMS')"
              class="button-danger-style m-2"
              size="sm"
              variant="success"
              @click.prevent.stop="handleUpdateService(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <b-button
              class="button-duplicate-style ml-1"
              size="sm"
              variant="info"
              title="Duplication"
              @click.prevent.stop="duplicateService(data.item)"
              v-if="checkPermission('GSHTDS')"
            >
              <font-awesome-icon icon="copy" />
            </b-button>
          </template>
        </b-table>
        <b-modal
          id="updateService"
          ref="updateService"
          title="Modifier service"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap modal-dialog-service"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Modifier service</div>
            <div class="iconClose" @click.prevent="hideModal('updateService')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="submitUpdateService"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom"
                  label-for="nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="nom"
                    v-model="serviceToUpdate.nom"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="messageError">
                  <div v-if="errorNomService" class="error">
                    <ul
                      v-if="Array.isArray(errorNomService)"
                      class="mb-0"
                      style="list-style-type: none"
                    >
                      <li v-for="(e, index) in errorNomService" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorNomService }}</div>
                  </div>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Description"
                    label-for="description"
                    class="input-modal-champ"
                  >
                    <b-form-textarea
                      id="description"
                      row="5"
                      v-model="serviceToUpdate.description"
                      style="border-radius: 17px; height: 150px"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="errorService" class="error">
                      <ul
                        v-if="Array.isArray(errorService)"
                        class="mb-0"
                        style="list-style-type: none"
                      >
                        <li v-for="(e, index) in errorService" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorService }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style"
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-modal
          id="duplicateService"
          ref="duplicateService"
          title="Dupliquer service"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap modal-dialog-service"
        >
          <div class="hader mb-2">
            <div class="titleSetting">
              Dupliquer un service
            </div>
            <div
              class="iconClose"
              @click.prevent="hideModal('duplicateService')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="submitDuplicateService"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom"
                  label-for="nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="nom"
                    v-model="serviceToUpdate.nom"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="messageError">
                  <div v-if="errorNomService" class="error">
                    <ul
                      v-if="Array.isArray(errorNomService)"
                      class="mb-0"
                      style="list-style-type: none"
                    >
                      <li v-for="(e, index) in errorNomService" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorNomService }}</div>
                  </div>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Description"
                    label-for="description"
                    class="input-modal-champ"
                  >
                    <b-form-textarea
                      id="description"
                      row="5"
                      v-model="serviceToUpdate.description"
                      style="border-radius: 17px; height: 150px"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="errorService" class="error">
                      <ul
                        v-if="Array.isArray(errorService)"
                        class="mb-0"
                        style="list-style-type: none"
                      >
                        <li v-for="(e, index) in errorService" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorService }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style"
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-modal
          id="deleteService"
          ref="deleteService"
          title="Suprrimer service"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Supprimer un service</div>
            <div class="iconClose" @click.prevent="hideModal('deleteService')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="submitDeleteService"
                class="form-modal-custom-style"
              >
                <div class="mt-4 ml-3">
                  <p class="text-dark">
                    Êtes-vous sur de vouloir supprimer le service
                    <strong>{{ serviceToDelete.nom }}</strong>
                    ?
                  </p>
                </div>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="'error' + index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style mt-1"
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>

        <div class="footer-style mt-2">
          <!-- <b-pagination
            v-model="page"
            :total-rows="getTotalRowConfComptaRh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination> -->

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        {
          key: 'nom',
          label: 'Service'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      serviceToAdd: {
        nom: null,
        description: null
      },
      loading: false,
      error: null,
      serviceToDelete: null,
      serviceToUpdate: null
    };
  },
  methods: {
    ...mapActions([
      'resetCommit',
      'getServices',
      'createService',
      'updateService',
      'deleteService'
    ]),
    duplicateService(item) {
      this.resetCommit();
      this.serviceToUpdate = { ...item };
      this.$refs['duplicateService'].show();
    },
    async submitDeleteService() {
      this.loading = true;
      const response = await this.deleteService(this.serviceToDelete);
      if (response.success) {
        this.loading = false;
        this.hideModal('deleteService');
        this.resetCommit();
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    handleDeleteService(item) {
      this.resetCommit();
      this.serviceToDelete = item;
      this.$refs['deleteService'].show();
    },
    handleUpdateService(item) {
      this.serviceToUpdate = { ...item };
      this.$refs['updateService'].show();
    },
    async submitDuplicateService() {
      this.loading = true;
      const response = await this.createService(this.serviceToUpdate);
      if (response) {
        this.loading = false;
        this.hideModal('duplicateService');
        this.resetCommit();
      } else {
        this.loading = false;
      }
    },
    async submitUpdateService() {
      this.loading = true;
      const response = await this.updateService(this.serviceToUpdate);
      if (response) {
        this.loading = false;
        this.hideModal('updateService');
        this.resetCommit();
      } else {
        this.loading = false;
      }
    },
    async submitAddService() {
      this.loading = true;
      const response = await this.createService(this.serviceToAdd);
      if (response) {
        this.loading = false;
        this.hideModal('addService');
        this.resetCommit();
      } else {
        this.loading = false;
      }
    },
    async changePerPage() {
      this.page = 1;
      await this.getServices({
        perPage: this.perPage
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getServices({
        perPage: this.perPage
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.loading = false;
      this.serviceToAdd = {};
      this.error = null;
      this.serviceToDelete = {};
      this.serviceToUpdate = {};
      this.resetCommit();
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'services',
      'loadingService',
      'errorService',
      'errorNomService'
    ])
  },
  components: {
    Card: () => import('../component/card.vue')
  },
  async mounted() {
    await this.getServices({
      perPage: this.perPage
    });
  }
};
</script>

<style scoped lang="scss">
.button-duplicate-style {
  background-color: #17a2b8;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  // font-weight: 600;
  color: #ffffff;
  // border-radius: 25px;
  // padding: 6px 15px;
  border: none;
}
.contentProduct {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .table-gestion-categories-style {
    max-height: calc(100vh - 247px) !important;
    height: calc(100vh - 247px) !important;
    margin-bottom: 0px;
    max-width: 100%;
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .body-box-setting {
    height: calc(100vh - 170px) !important;
  }
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
.tableFixHead {
  position: sticky;
  top: -10px;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 170px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}

.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
</style>
